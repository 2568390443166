@use '@/styles/utils/mixins.scss' as *;

.specificNumbersWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  .specificNumberCardsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.5rem;
    row-gap: 3.75rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
      gap: 2.5rem;
    }
    .downloadLink {
      font-size: 1rem;
      font-weight: 500;
      color: $primary;
    }
    .specificNumberCard {
      background-color: $white;
      border-radius: 1.25rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
      padding: 2.5rem 3rem;
      
      @include tab() {
        padding: 2.5rem 1.5rem;
        -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
        -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
        box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      }

      .departmentList {
        display: flex;
        flex-direction: column;
        gap: 1.875rem;
      }
    }
    .specificNumberCardHeader {
      display: flex;
      gap: 1.875rem;
      @include tab() {
        flex-direction: column;
      }
      .rightSectionContent {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        .specificNumberCardTitle {
          font-size: 1.5rem;
          color: $primary;
          font-weight: 500;
        }
        .specificNumberCardDescription {
          font-size: 1rem;
          color: $neutral08;
          font-weight: 500;
        }
      }
    }

    .contactListItem {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 0;

      .contactLink {
        font-size: 1.2rem;
        font-weight: 500;
        color: $dark;
        transition: all 0.2s ease-in-out;
        display: flex;
        gap: 0.25rem;
        align-items: flex-start;
        word-break: break-all;
        span {
          padding-top: 0.313rem;
        }
        .contactIcon {
          height: 1.875rem;
          width: 1.875rem;
          flex: 0 0 auto;
        }
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
  }
}
